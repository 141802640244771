import { useEffect } from "react"

const OnBoardForm = () => {
    useEffect(() => {
        window.location.href = "https://share.hsforms.com/18S8MulggQ6eM4D4l8Ctjqwd8664"
    },[])
    return(
        <h3 className="text-center my-5">Redirecting to order request form...</h3>
    )
}

export default OnBoardForm