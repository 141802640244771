// assets
import largeCan from '../assets/home_filler_large.png'
import mainCan from '../assets/main_can.png'
import mainTwoCan from '../assets/main_can2.jpg'
import smallCan from '../assets/home_filler_small.png'
import recycle from '../assets/recycle2.png'
import reusable from '../assets/reusable2.png'
import shelfstable from '../assets/shelfstable2.png'
import waste from '../assets/waste2.png'
import rtdIcon from '../assets/rtd_icon.png'
import rtpIcon from '../assets/rtp_icon.png'
import formulaIcon from '../assets/formulation_icon.png'
import eventsIcon from '../assets/events_icon.png'
import carouselImg from '../assets/carousel_img.png'
import check from '../assets/check.svg'
import carrot from '../assets/carrot.svg'
import slideOne from '../assets/slider_one.png'
import slideTwo from '../assets/slider_two.png'
import slideThree from '../assets/slider_three.png'
import slideFour from '../assets/slider_four.png'
import sponsorsImg from '../assets/sponsors.png'


// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// react router
import { NavLink } from "react-router-dom"
import Banner from '../features/banner'

const Home = () => {
    return(
        <main className="container">

            <section className="d-flex flex-column justify-content-center align-items-center">
                <h1 className="text-center">Make More Money with <br /> QuickServe Multi-Platform <br />  Ready-to-Drink Beverage Systems </h1>

                <NavLink to="/contact">
                    <button className="qs-button-main">Request Free Consultation <span><img src={carrot} alt="" /></span> </button>
                </NavLink>
            </section>

            <section className="qs-swiper-size">
                <Swiper
                breakpoints={{
                1000: {
                    slidesPerView: 1,
                },
                1024: {
                    slidesPerView: 1.35,
                    spaceBetween: 30,
                },
                }}
                    modules={[Pagination, Autoplay]}
                    centeredSlides={true}
                    pagination={{
                    clickable: true,
                    }}
                    autoplay={{
                        delay: 3000,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false
                        }}
                    loop
                    className="mySwiper"
                    style={{
                            "--swiper-pagination-color": "#000000",
                    }}
                >
                    <SwiperSlide><img src={slideOne} alt="" className='img-fluid p-5'/></SwiperSlide>
                    <SwiperSlide><img src={slideTwo} alt="" className='img-fluid p-5'/></SwiperSlide>
                    <SwiperSlide><img src={slideThree} alt="" className='img-fluid p-5'/></SwiperSlide>
                    <SwiperSlide><img src={slideFour} alt="" className='img-fluid p-5'/></SwiperSlide>
                </Swiper>
            </section>

            <section className="
                qs-home-hero-bg 
                d-flex 
                flex-column 
                justify-content-between 
                align-items-center
                ">
                {/* <h1 className="w-50 text-center">Multi-Platform Ready-to-Drink Beverage Systems Designed to Make You More Money </h1>

                <NavLink to="/contact">
                    <button className="qs-button-main">Request Consultation - For Free <span><img src={carrot} alt="" /></span> </button>
                </NavLink> */}
                <img src={mainCan} alt="" className="img-fluid "/>
            </section>

            <section>
                <div className="
                    d-flex 
                    flex-column 
                    text-center">

                    <div className="
                        row 
                        row-cols-1 
                        row-cols-lg-4
                        gy-5">
                        <div className="col">
                            <div className="
                                qs-home-serviceone
                                container
                                d-flex
                                flex-column
                                justify-content-evenly
                                align-items-center
                                rounded-4
                                ">
                                <h2 className="">Ready-To-Drink</h2>
                                <img src={rtdIcon} alt="" />
                                <NavLink to="services" className="btn">Learn More <span><img src={carrot} alt="" /></span> </NavLink>
                            </div>
                        </div>
                        <div className="col">
                            <div className="
                                qs-home-servicetwo
                                container
                                d-flex
                                flex-column
                                justify-content-evenly
                                align-items-center
                                rounded-4
                                ">
                                <h2 className="">Ready-To-Pour</h2>
                                <img src={rtpIcon} alt="" />
                                <NavLink to="services" className="btn">Learn More <span><img src={carrot} alt="" /></span> </NavLink>
                            </div>
                        </div>
                        <div className="col">
                            <div className="
                                qs-home-servicethree
                                container
                                d-flex
                                flex-column
                                justify-content-evenly
                                align-items-center
                                rounded-4
                                ">
                                <h2 className="">Recipe Formulation</h2>
                                <img src={formulaIcon} alt="" />
                                <NavLink to="services" className="btn">Learn More <span><img src={carrot} alt="" /></span> </NavLink>
                            </div>
                        </div>
                        <div className="col">
                            <div className="
                                qs-home-servicefour
                                container
                                d-flex
                                flex-column
                                justify-content-evenly
                                align-items-center
                                rounded-4
                                ">
                                <h2 className="">Events</h2>
                                <img className="img-lfuid" src={eventsIcon} alt="" />
                                <NavLink to="services" className="btn">Learn More <span><img src={carrot} alt="" /></span> </NavLink>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* <section>
                <div className="
                    row 
                    g-4 
                    justify-content-md-center
                    my-5">

                    <div className="col-lg-3 col-12 text-center">
                        <div className="
                            container 
                            qs-home-serviceone 
                            card
                            border-0
                            d-flex
                            flex-column
                            justify-content-center
                            rounded-4
                            ">
                            <h2 className="">Ready-To-Drink</h2>
                            <img src={rtdIcon} alt="" />
                            <button className="btn">Learn More <span><img src={carrot} alt="" /></span> </button>
                        </div>
                    </div>

                    <div className="col-lg-3 col-12 text-center">
                        <div className="
                            container 
                            qs-home-servicetwo 
                            card
                            border-0
                            d-flex
                            flex-column
                            justify-content-center
                            rounded-4">
                            <h2 className="">Ready-To-Pour</h2>
                            <img src={rtpIcon} alt="" />
                            <button className="btn">Learn More <span><img src={carrot} alt="" /></span> </button>
                        </div>
                    </div>

                    <div className="col-lg-3 col-12 text-center">
                        <div className="
                            container 
                            qs-home-servicethree 
                            card
                            border-0
                            d-flex
                            flex-column
                            justify-content-center
                            rounded-4">
                            <h2 className="">Recipe Formulation</h2>
                            <img src={formulaIcon} alt="" />
                            <button className="btn">Learn More <span><img src={carrot} alt="" /></span> </button>
                        </div>
                    </div>

                    <div className="col-lg-3 col-12 text-center">
                        <div className="
                            container 
                            qs-home-servicefour 
                            card
                            border-0
                            d-flex
                            flex-column
                            justify-content-center
                            rounded-4">
                            <h2 className="">Events</h2>
                            <img className="img-lfuid" src={eventsIcon} alt="" />
                            <button className="btn">Learn More <span><img src={carrot} alt="" /></span> </button>
                        </div>
                    </div>

                </div>
            </section> */}

            <section className="qs-home-list rounded-4 my-5">
                <div className="
                    d-flex 
                    flex-column
                    justify-content-center
                    align-items-center
                    py-5
                    px-4
                    ">

                    <h1 className="text-center pb-5">Explore the Benefits <br /> of These Systems</h1>

                    <ul className="qs-home-bullet-points">
                        <li > Increase revenue by decreasing wait times</li>
                        <li >  Quality + consistency = customer satisfaction</li>
                        <li >  Increase revenue generating space</li>
                        <li >  Reduce spillage</li>
                        <li >  Store beverages without refrigeration </li>
                        <li >  Lower staffing requirements</li>
                        <li >  Higher tips pools lead to happier employees</li>
                    </ul>

                </div>
            </section>

            <section>
                <div className="
                    d-flex 
                    flex-column 
                    text-center">

                    <h1 className="pb-5">Socially Conscious <br /> and Environmentally <br /> Friendly</h1>

                    <div className="
                        row 
                        row-cols-1 
                        row-cols-lg-4
                        gy-5">
                        <div className="col">
                            <div className="
                                qs-home-selling-points
                                container
                                d-flex
                                flex-column
                                justify-content-evenly
                                align-items-center
                                rounded-4
                                ">
                                <img src={recycle} alt="" />
                                <h1>Recyclable</h1>
                            </div>
                        </div>
                        <div className="col">
                            <div className="
                                qs-home-selling-points
                                container
                                d-flex
                                flex-column
                                justify-content-evenly
                                align-items-center
                                rounded-4
                                ">
                                <img src={waste} alt="" />
                                <h1>Less Waste</h1>
                            </div>
                        </div>
                        <div className="col">
                            <div className="
                                qs-home-selling-points
                                container
                                d-flex
                                flex-column
                                justify-content-evenly
                                align-items-center
                                rounded-4
                                ">
                                <img src={reusable} alt="" />
                                <h1>Re-usable</h1>
                            </div>
                        </div>
                        <div className="col">
                            <div className="
                                qs-home-selling-points
                                container
                                d-flex
                                flex-column
                                justify-content-evenly
                                align-items-center
                                rounded-4
                                ">
                                <img src={shelfstable} alt="" className="img-fluid"/>
                                <h1>Shelf-Stable</h1>
                            </div>
                        </div>
                    </div>

                <div className="
                    d-flex 
                    flex-column 
                    justify-content-center 
                    align-items-center
                    mt-5
                    py-5">
                    <h1 className="py-5">Select Clients and Events</h1>
                    <img className="img-fluid" src={sponsorsImg} alt="" />
                </div>

                </div>
            </section>

            <Banner />

            {/* <section>
                <Swiper
                breakpoints={{
                640: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 1.35,
                    spaceBetween: 30,
                },
                }}
                    modules={[Pagination, Autoplay]}
                    centeredSlides={true}
                    pagination={{
                    clickable: true,
                    }}
                    autoplay={{
                        delay: 2000,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false
                        }}
                    loop
                    className="mySwiper pb-5 my-5"
                    style={{
                            "--swiper-pagination-color": "#000000",
                    }}
                >
                    <SwiperSlide><img src={slideOne} alt="" className='img-fluid'/></SwiperSlide>
                    <SwiperSlide><img src={slideTwo} alt="" className='img-fluid'/></SwiperSlide>
                    <SwiperSlide><img src={slideThree} alt="" className='img-fluid'/></SwiperSlide>
                    <SwiperSlide><img src={slideFour} alt="" className='img-fluid'/></SwiperSlide>
                </Swiper>
            </section> */}

        </main>
    )
}

export default Home