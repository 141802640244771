import servicesImg from '../assets/services_img.png'
import carrot from '../assets/carrot.svg'
import check from '../assets/check.svg'
// import eventsImg from '../assets/events.png'
import eventsImg from '../assets/events2.png'
// import rtpImg from '../assets/rtp.png'
import rtpImg from '../assets/rtp2.png'
// import rtdImg from '../assets/rtd.png'
import rtdImg from '../assets/rtd2.png'
// import rcpeImg from '../assets/recipeformulation.png'
import rcpeImg from '../assets/recipeformulation2.png'

import { NavLink } from 'react-router-dom'
import Banner from '../features/banner'

const Services = () => {
    return(
        <main className="container">

            <header className="d-flex flex-column justify-content-center align-items-center my-5 text-center">
                    <h1>Services</h1>
                    <p className="col-12 col-xl-5">Multi-platform QuickServe Systems</p>
            </header>

            <section id="servicesone" className="qs-services-service-one p-5 rounded-4 my-5">
                <div className="row">
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center order-first ">
                        <h2 className="fw-bold">Ready-To-Drink (RTD) Systems</h2>

                    <ul>
                        <li >  Fully customizable in:
                            <ul className="qs-bullet-points">
                                <li>Can design</li>
                                <li>Flavor profile</li>
                            </ul>
                        </li>
                        <li >  Works with any brand as a base</li>
                        <li >  Shelf-stable</li>
                        <li >  Made with all natural flavors </li>
                        <li >  100+ base recipes ready for selection </li>
                    </ul>
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-between">
                        <img src={rtdImg} alt="" className="img-fluid"/>
                    </div>
                </div>
            </section>

            <section id="servicestwo" className="qs-services-service-two p-5 rounded-4 my-5">
                <div className="row">
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-between">
                        <img src={rtpImg} alt="" className="img-fluid"/>
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center order-first order-md-last">
                        <h2 className="fw-bold">Ready-To-Pour (RTP) Systems</h2>

                    <ul>
                        <li >  QuickServe Cocktails systems improve serving speeds up to 400% </li>
                        <li >  Systems include: </li>
                            <ul className="qs-bullet-points">
                                <li>Kegerators ready for rent or purchase designed to dispense cocktails and mocktails</li>
                                <li>Converted soda gun systems with bag-in-box recipes to shoot cocktails directly out of the soda guns</li>
                                <li>Slushie machines, and recipes customized to be used in these machines</li>
                            </ul>
                        <li >  All RTP systems will reduce COGS and labor, while maintaining consistency and quality</li>

                    </ul>
                    </div>
                </div>
            </section>

            <section className="qs-services-service-one p-5 rounded-4 my-5">
                <div className="row">
                    <div className="col-12 col-md-6 d-flex flex-column justify-between">
                        <img src={rcpeImg} alt="" className="img-fluid"/>
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center order-first ">
                        <h2 className="fw-bold">Recipe Formulation</h2>
                    <ul>
                        <li >  Our in-house formulation team allows for the the customization and shelf-stabilization of any concept</li>
                        <li >  Come to us with a recipe in mind, or ask for us to help curate one for you</li>
                        <li >  Recipes can be canned, kegged, bagged, or bottled</li>
                        <li >  Over 100 shelf-stable recipes already developed</li>
                    </ul>
                    </div>
                </div>
            </section>

            <section className="qs-services-service-two p-5 rounded-4 my-5">
                <div className="row">
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                        <img src={eventsImg} alt="" className="img-fluid"/>
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center order-first order-md-last">
                        <h2 className="fw-bold">Events</h2>
                        <ul>
                            <li > We cover almost all needs for your events:
                                <ul className="qs-bullet-points">
                                    <li>Staffing</li>
                                    <li>Permitting</li>
                                    <li>Planning</li>
                                </ul>
                            </li>
                            <li > One stop shop:
                                <ul className="qs-bullet-points">
                                    <li>Helps minimize miscommunication</li>
                                    <li>Saves you time on coordinating logistics</li>
                                    <li>Saves you money </li>
                                </ul>                                
                            </li>
                            {/* <li > Using a singular company for all services:
                                <ul className="qs-bullet-points">
                                    <li>Helps minimize miscommunication and errors</li>
                                    <li>Saves you time on coordinating logistics</li>
                                    <li>Saves you money </li>
                                </ul>                                
                            </li> */}
                            <li >  Need advice? Leverage our experience with over 100+ activated events</li>
                            {/* <li >  Being professionals in the event space, we can also help give recommendations based on previous experience</li> */}
                        </ul>
                    </div>
                </div>
            </section>

            <Banner />

        </main>
    )
}

export default Services