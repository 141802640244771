
const Banner = () => {
    

    return (
    <section>
        <div className="
            d-flex
            flex-column 
            justify-content-center 
            align-items-center
            py-5
            px-4
            rounded-4
            text-center
            qs-home-next-steps">
            <h1> Looking for something quick and easy, <br className="d-none d-md-block" /> yet still delicious? Check out our <br className="d-none d-md-block" /> award-winning retail canned cocktail line</h1>
            <a href="https://enjoythirsttrap.com/" target="_blank">
                <button className="qs-button-main">Thirst Trap ></button>
            </a>
        {/* <NavLink to="/contact">
            <button className="qs-button-main">ThirstTrap <span><img src={carrot} alt="" /></span></button>
        </NavLink> */}
        </div>
    </section>
    )
}

export default Banner