import { useState } from "react"
import { Link } from "react-router-dom"

const AgeAuthentication = ({ setVerified}) => {
    const [msg, setMsg] = useState(false)

    return (
        <div className="qs-verification-container vh-100 d-flex flex-column justify-content-center align-items-center bg-primary">
            <div className="tt-verification-card border border-dark border-2 rounded-2 h-50 d-flex flex-column  p-3 bg-white mx-4">
                <h3 className="text-start px-2">QUICKSERVE</h3>
                <div className="d-flex justify-content-center align-items-center h-100">
                    {msg ? 
                        <h1 className="fw-bold text-center w-75"> You must be of legal drinking age to enter this site</h1> :
                        <div>
                            <h1 className="fw-bold text-center">Are you of legal age to drink?</h1>
                            <div className="my-5 d-flex justify-content-evenly w-100">
                                <Link to="/"><button className="btn tt-button" onClick={() => {
                                    setVerified(true)
                                }}  >I'm over 21</button></Link>
                                <button className="btn tt-button bg-white" onClick={() => {
                                    setVerified(false)
                                    setMsg(true)
                                }} >Not yet</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AgeAuthentication