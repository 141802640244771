import { useEffect } from "react"

const ReOrderForm = () => {
    useEffect(() => {
        window.location.href = "https://forms.gle/LaevQSrUApzij1yp9"
    },[])
    return(
        <h3 className="text-center my-5">Redirecting to order request form...</h3>
    )
}

export default ReOrderForm