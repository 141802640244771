import { useState } from "react"
import { object, string } from 'yup';
import { useFormik } from "formik"
import emailjs from '@emailjs/browser';

import phone from '../assets/phone.svg'
import mail from '../assets/mail.svg'
import directions from '../assets/directions.svg'

const Contact = () => {
    const apiKey =  process.env.REACT_APP_EMAIL_JS_KEY
    const service =  process.env.REACT_APP_EMAIL_JS_SERVICE
    const template = process.env.REACT_APP_EMAIL_JS_TEMPLATE
    const [submitted, setSubmitted] = useState(false)

    function onSubmit (values) {
        console.log(values)

        emailjs.send(service, template, values, apiKey)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        setSubmitted(true)
    }

    const validationSchema = object({
        name: string().required(),
        email: string().email().required(),
        phone: string(),
        subject: string().required(),
        message: string().required()
    })

    const formik =  useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            subject:"",
            message:"",
        },
        validationSchema: validationSchema,
        onSubmit,
    })    

    return(
        <main className="container">
                    
            <header className="d-flex flex-column justify-content-center align-items-center my-5 text-center">
                <h1>Contact</h1>
                <p className="col-12 col-xl-5">Have a question? Want to learn more? Fill out the form below and we will get right back to you.</p>
            </header>


            {submitted ? 
                <div className="d-flex flex-column justify-content-center align-items-center px-5">
                    <p className="text-center"><i className="text-primary bi bi-check-circle"></i></p>
                    <h2 className="text-center">Thank you for contacting us</h2>
                    <p >QUICKSERVE</p>
                </div>
                :
                <form onSubmit={formik.handleSubmit} action="" className="
                    qs-contact-form 
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    p-5
                    rounded-4 
                    container-fluid
                    w-100">
                    <div class="mb-5">
                        <label 
                            for="name" 
                            class={`form-label ${formik.errors.name && formik.touched.name ? `text-danger` : null} `}>
                            Name
                            <span className="text-danger" >{formik.errors.name && formik.touched.name ? "*" : null}</span>
                        </label>
                        <input 
                            type="text" 
                            class="form-control border-0" 
                            id="name"  
                            value={formik.values.name} 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}/>
                    </div>

                    <div class="mb-5">
                        <label 
                            for="email" 
                            class={`form-label ${formik.errors.email && formik.touched.email ? `text-danger` : null} `}>
                            Email
                            <span className="text-danger" >{formik.errors.email && formik.touched.email ? "*" : null}</span>
                        </label>
                        <input 
                            type="email" 
                            class="form-control border-0" 
                            id="email" 
                            value={formik.values.email} 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}/>
                    </div>

                    <div class="mb-5">
                        <label 
                            for="phone" class="form-label">Phone (Optional) </label>
                        <input 
                            type="text" 
                            class="form-control border-0" 
                            id="phone" 
                            value={formik.values.phone} 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}/>
                    </div>

                    <div class="mb-5">
                        <label 
                            for="subject" 
                            class={`form-label ${formik.errors.subject && formik.touched.subject ? `text-danger` : null} `}>
                            Subject
                            <span className="text-danger" >{formik.errors.subject && formik.touched.subject ? "*" : null} </span> 
                        </label>
                        <input 
                            type="text" 
                            class="form-control border-0" 
                            id="subject" 
                            value={formik.values.subject} 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}/>
                    </div>

                    <div class="mb-5">
                        <label 
                            class={`form-label ${formik.errors.message && formik.touched.message ? `text-danger` : null} `} 
                            for="floatingTextarea2">
                            Your Message
                            <span className="text-danger" >{formik.errors.message && formik.touched.message ? "*" : null}</span>
                        </label>
                        <textarea 
                            name="message"
                            class="form-control border-0" 
                            rows="5"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            ></textarea>
                    </div>

                    <button type="submit" class="qs-button-main btn-black">Send ></button>
                </form>
                }

                <div className="qs-contact-container row mt-3 justify-content-center justify-content-md-between px-3 gx-5">
        
                    <div className="qs-contact-half col-12 col-md-6 rounded-4">
                        <img  className="img-fluid" src={require("../assets/contact.png")} alt="" />
                        {/* <p className="py-5 " >Experience the most hands-on trial run for canning drinks with Newport Bottling & Canning. Trials runscreate a unique experience for entrepreneurs and take the stress out of canning drinks. Our flavor chemists and quality control team are ready for any new requests or adjustments from you.
                        </p> */}
                    </div>

                    <div className="qs-contact-half col-12 col-md-6 pt-3 pt-md-0">

                    {/* <div className=" col-12 col pt-5 pt-xxl-2 "> */}
                        <div className="row h-100">
                            <div className="qs-contact-info col-12 mb-3 rounded-4 d-flex justify-content-center align-items-center">
                                <p><span><img src={phone} alt="" /></span>310-981-3388</p>
                            </div>
                            <div className="qs-contact-info col-12 mb-3 rounded-4 d-flex justify-content-center align-items-center">
                                <p><span><img src={mail} alt="" /></span>sales@quickservecocktails.com</p>
                            </div>
                            <div className="qs-contact-info col-12 mb-3 rounded-4 d-flex justify-content-center align-items-center">
                                <p><span><img src={directions} alt="" /></span>PO Box 24996 LA CA 90024</p>
                            </div>
                        </div>
                    </div>

                </div>
            

        </main>
    )
}

export default Contact