import { useState } from "react"
import { NavLink } from "react-router-dom"

// Assets
import logo from '../assets/QS_logo.png'
import carrot from '../assets/carrot.svg'

const Navbar = () => {
    const [hamburgerToggled, setHambuerToggled] = useState(false)

    // function toggleMenu () {
    //     setHambuerToggled((hamburgerToggled) => !hamburgerToggled)
    // }

    return (
        <header id="header">
            <nav className="navbar fixed-top navbar-expand-xxl bg-white">
                <div className="container">
                    <NavLink to="/">
                        <div className="navbar-brand" >
                            <img src={logo} alt="" />
                        </div >
                    </NavLink>
                    <NavLink to="/contact" className="d-block d-xxl-none ms-auto">
                        <button className="btn p-0 m-0 qs-contact-btn-size">Request Free Consultation <span><img src={carrot} alt="" /></span></button>
                    </NavLink>
                    <button className="navbar-toggler collapsed" type="button"  data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className={hamburgerToggled ? `fs-1 bi bi-x-lg` : `navbar-toggler-icon`}></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end align-items-center" id="navbarNav">
                        <ul id="nav" className="navbar-nav">
                            <li >
                                <NavLink to="/" className="nav-item nav-link ps-3">
                                    Home
                                </NavLink>
                            </li>
                            <li >
                                <NavLink to="/services" className="nav-item nav-link ps-3">
                                    Services
                                </NavLink>
                            </li>
                            <li >
                                <NavLink to="/about" className="nav-item nav-link ps-3">
                                    About Us
                                </NavLink>
                            </li>
                            <li >
                                <NavLink to="/contact" className="nav-item nav-link ps-3">
                                    Contact
                                </NavLink>
                            </li>
                            <NavLink to="/contact" className="d-none d-xxl-block">
                                <button className="qs-button-main ms-0 ms-xxl-5 px-4">Request Free Consultation <span><img src={carrot} alt="" /></span></button>
                            </NavLink>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Navbar