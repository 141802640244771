import { NavLink } from "react-router-dom"
import CountUp from 'react-countup';
import Banner from "../features/banner";

const About = () => {
    return(
        <main className="container">

            <header className="d-flex flex-column justify-content-center align-items-center my-5 text-center">
                <h1>About Us</h1>
            </header>

            <section className="qs-about-what-we-do d-flex flex-column justify-content-center align-items-center rounded-4 my-5">
                <p className="col-12 col-xl-5 p-5 p-xl-0">We are consulting experts in the hospitality industry which provides events and venues with multi-platform ready-drink (RTD) | ready-to-pour (RTP) beverage systems and/or providers. Clients can choose from several services to outfit their venue or streamline an event in a more efficient way, enabling them to reduce costs and labor while still providing premium beverages. </p>
            </section>

            <section>
                <div className="
                    row 
                    row-cols-1 
                    row-cols-md-3 
                    g-4 
                    justify-content-md-center
                    my-5">

                    <div className="col text-center">
                        <div className="
                            container 
                            qs-about-cards
                            card
                            rounded-4
                            border-0
                            d-flex
                            flex-column
                            justify-content-center">
                            <h2 className="qs-about-selling-points">100+</h2>
                            <p >Clients | Events</p>
                        </div>
                    </div>

                    <div className="col text-center">
                        <div className="
                            container 
                            qs-about-cards 
                            card
                            rounded-4
                            border-0
                            d-flex
                            flex-column
                            justify-content-center">
                            <h2 className="qs-about-selling-points">100%</h2>
                            <p>Delicious</p>
                        </div>
                    </div>

                    <div className="col text-center">
                        <div className="
                            container 
                            qs-about-cards
                            card
                            rounded-4
                            border-0
                            d-flex
                            flex-column
                            justify-content-center">
                            <CountUp className="qs-about-selling-points text-dark" start={500000} end={550000} duration={3000}>
                                 {/* <h2 className="qs-about-selling-points">500,000</h2> */}
                            </CountUp>
                            <p>Servings Produced</p>
                        </div>
                    </div>

                </div>
            </section>

            <section className=" my-5">
                <h1 className=" text-center mb-5">Our History</h1>
                <div className="row justify-content-center gx-5">
                    {/* <div className="qs-about-written-content col-12 col-md-6"> */}
                    <div className="qs-about-written-content col-12 col-md-6">
                                        <p >Thirst Trap | QuickServe Cocktails emerged from our response to the challenges posed by the 2020 pandemic. Initially, we adapted by converting craft cocktails into formats suitable for to-go sales, utilizing innovative dispensing methods such as bag-in-box and draft lines. This evolved into creating mini production lines for micro-canning to produce shelf-stable recipes and premium beverages for both on and off premise consumption.</p>
                                                        <p >We case studied the technologies and business in the innovation Lab the Wharton School of Buisness 2021 MBA Class. To our humbled suprise, we won the in-class competition of over 50+ ideas. Hence, we realized we were onto something.</p>
                <p >As hospitality venues re-opened, we further enhanced our offerings and started gaining a multitude of diverse clients and enabled them to reduce costs and labor significantly while ensuring consistent quality. Thirst Trap | QuickServe Cocktails officially launched Q4 2023, and we have been honored to be experiencing rapid growth. </p>

                    </div>
                    <div className="qs-about-written-content  col-12 col-md-6 rounded-4 ">
                        <img  className="img-fluid" src={require("../assets/ourhistory.png")} alt="" />
                        {/* <p className="text-center">PICTURE HERE</p> */}
                    </div>
                    {/* <div className="qs-about-written-content col-12 col-md-6">
                    </div> */}
                </div>
            </section>

        <Banner />

        </main>
    )
}

export default About